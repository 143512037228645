<template>
    <div class="upload_sample">
        <div class="upload_sample_container">
            <div class="upload_sample_container_title">
                <p><span>Refuse to accept orders</span></p>
                <img class="upload_sample_container_title_close"
                    src="../../../assets/images/supplier/quotation/if-close.png" alt="" @click="CloseDialog('close')">
            </div>

            <div class="upload_sample_container_form">

                <div class="upload_sample_container_form_item1">
                    <el-form ref="form" :model="form1" @submit.native.prevent="submitForm">
                        <el-form-item label="Reasons for refusing to accept orders" prop="reason">
                            <el-input v-model="form1.reason"
                                placeholder="Enter Reasons for refusing to accept orders"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="submitForm">Confirm</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup name="DeclineOrder">
import { ref, inject, defineProps } from 'vue'
import { declineQuotation } from '@/api/quotation'

// 关闭弹窗
import { defineEmits } from 'vue'
const form1 = ref({
  reason: ''
})
const props = defineProps({
  supplierquotation: {}
})
// 上面是表单数据

// 接收父组件传递的数据
const counter = inject('orderId')
console.log(counter.value)

const submitForm = async () => {
  console.log('Form data:', form1.value)
  const result = await declineQuotation(props.supplierquotation)
  console.log('result===', result)
  CloseDialog('declineOrder')
}
const emit = defineEmits(['customEvent'])
const CloseDialog = (msg2) => {
  console.log(msg2)
  emit('customEvent', msg2)
}

</script>

<style lang="scss" scoped>
.upload_sample {

    .upload-demo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        border-radius: 4px;
        overflow: hidden;
    }

    .upload-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .upload-icon {
        font-size: 28px;
    }

    position: fixed;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    line-height: 1;
    text-align: left;
    overflow-y: scroll;

    &_container {
        width: 890px;
        margin: 80px auto 0;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 300px;

        &_title {
            display: flex;
            justify-content: space-between;
            padding: 30px;
            background-color: rgba(248, 248, 248, 1);

            P {
                font-size: 20px;
                margin: 0;
                font-weight: 700;
                color: rgba(24, 24, 24, 1);
            }

            &_close {
                width: 20px;
                height: 20px;
                cursor: pointer;

            }
        }

        &_form {
            margin-top: 50px;

            &_item1 {
                ::v-deep .el-form {
                    width: 730px;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .el-form-item {
                        margin-bottom: 30px;
                        width: 350px;
                        display: flex;
                        flex-direction: column;

                        .el-form-item__label {
                            width: 340px;
                            justify-content: flex-start;
                            font-size: 14px;
                            line-height: 1;
                            height: 14px;
                            margin-bottom: 10px;
                            color: rgba(24, 24, 24, 1);
                            font-weight: 300;
                        }

                        .el-form-item__content {
                            .el-input {
                                height: 46px;

                                .el-input__wrapper {
                                    padding: 0px 20px;
                                    box-shadow: none;
                                    background-color: rgba(249, 249, 249, 1);

                                    .el-input__inner {
                                        color: rgba(24, 24, 24, 1);
                                        font-size: 16px;

                                        &::placeholder {
                                            color: rgba(173, 173, 173, 0.6);
                                        }
                                    }
                                }

                                .is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }
                            }

                            .el-select {
                                width: 350px;

                                .el-input__wrapper.is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1) !important;
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }

                                .is-focus {
                                    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                                    border: 1px solid rgba(39, 174, 96, 1);
                                    box-sizing: border-box;
                                }

                            }
                        }

                        .el-button {
                            border-radius: 4px;
                            background-color: rgba(39, 174, 96, 1);
                            box-shadow: none;
                            border: none;
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: 700;
                            padding: 27px 30px;
                        }

                        &:last-child {
                            width: 890px;
                            justify-content: center;
                            align-items: center;
                            margin: 70px auto 80px;
                        }

                        &:first-child {
                            width: 890px;
                            justify-content: center;
                            margin: 0px auto 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>
