<!-- 供应商需求详情 -->

<template>
  <div class="container_requirement">
    <div class="requirement">
      <div class="requirement_left">
        <img class="requirement_left_mainImage" :src="currentImgSrc" alt="">
      </div>
      <div class="requirement_right">
        <div class="requirement_right_titleBox">
          <div class="requirement_right_titleBox_title">{{ demand.productName }}</div>

          <!-- 增加了多个产品，这个不用了 -->
          <!-- <div class="requirement_right_titleBox_quantity"><span>Quantity</span>{{ demand.quantity }}</div> -->
        </div>

        <!-- 产品1详情 -->
        <div class="requirement_right_details">
          <div class="requirement_right_details_list" v-for="(item, index) in keys" :key="item.key">
            <div class="requirement_right_details_list_title">{{ getTitle(item) }}</div>
            <div class="requirement_right_details_list_value">{{ (getDisplayedValue(item)===''||getDisplayedValue(item)==null)?'N/A':getDisplayedValue(item) }}</div>
          </div>
        </div>

        <!-- 产品1文件下载 -->
        <div class="requirement_right_details">
          <div class="requirement_right_details_list file">
            <div class="requirement_right_details_list_title">Original Artwork</div>
            <div class="requirement_right_details_list_value file_value" v-for="(item, index) in downLoadList" :key="index">
              <div class="file_value_download" @click="downLoad(index)">
                file{{ index + 1 }}
                <img src="../../assets//images//downloads.svg" alt="">
              </div>
            </div>
          </div>
          <div class="requirement_right_details_list file">
            <div class="requirement_right_details_list_title">Original Sample Picture</div>
            <div class="requirement_right_details_list_value file_value" v-for="(item, index) in demand.pictureList" :key="index">
              <div class="file_value_download" @click="downLoad2(item.imageUrl)">
                file{{ index + 1 }}
                <img src="../../assets//images//downloads.svg" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 产品2和后面的产品 -->
        <div class="requirement_right_details" v-for="(product, productIndex) in productInfoList" :key="productIndex">
          <div class="requirement_right_details_title">{{ product.productName }}</div>
          <div class="requirement_right_details_list" v-for="(item, index) in keys" :key="item.key">
            <div class="requirement_right_details_list_title">{{ getTitle(item) }}</div>
            <div class="requirement_right_details_list_value">{{ getDisplayedValue2(productIndex, item) }}</div>
          </div>

          <div class="requirement_right_details_list file">
            <div class="requirement_right_details_list_title">Original Artwork</div>
            <div class="requirement_right_details_list_value file_value" v-for="(img, imgIndex) in product.artworkList" :key="imgIndex">
              <div class="file_value_download" @click="downLoad2(img.imageUrl)">
                file{{ imgIndex + 1 }}
                <img src="../../assets//images//downloads.svg" alt="">
              </div>
            </div>
          </div>

          <div class="requirement_right_details_list file">
            <div class="requirement_right_details_list_title">Original Sample Picture</div>
            <div class="requirement_right_details_list_value file_value" v-for="(img, imgIndex) in product.pictureList" :key="imgIndex">
              <div class="file_value_download" @click="downLoad2(img.imageUrl)">
                file{{ imgIndex + 1 }}
                <img src="../../assets//images//downloads.svg" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 需求单通用数据 -->
        <div class="requirement_right_details commonData">
          <div class="requirement_right_details_list commonData_list" v-for="(item, index) in commonData" :key="item.key">
            <div class="requirement_right_details_list_title">{{ getCommonTitle(item) }}
            </div>
            <div class="requirement_right_details_list_value">{{ (getDisplayedValue(item)===''||getDisplayedValue(item)==null)?'N/A':getDisplayedValue(item) }}</div>
          </div>
        </div>

        <!-- 未报价显示 按钮 -->
        <div class="requirement_right_btn" v-if="quotationStatus == 0">
          <div class="requirement_right_btn_DeclineOrder requirement_btn" @click="toUploadDeclineOrder">Decline
            order</div>
          <div class="requirement_right_btn_MakeAQuotation requirement_btn" @click="toQuotation">Make a quotation
          </div>
        </div>

        <!-- 拒绝接单 显示 -->
        <div class="requirement_right_declineOrder" v-if="quotationStatus == 1">
          <el-popover placement="top" :width="300" trigger="hover" :content="reasonForRefusal">
            <template #reference>
              <div class="requirement_right_declineOrder_content">
                <img src="../../assets/images/supplier/emotion-unhappy.png" alt="">
                <div class="requirement_right_declineOrder_content_text">This order has been rejected</div>
              </div>
            </template>

          </el-popover>
        </div>

        <!-- 已报价 显示 点击后跳转报价单-->
        <div class="requirement_right_already-quoted" v-if="quotationStatus == 2">
          <div class="requirement_right_already-quoted_content">
            <img src="../../assets/images/supplier/check-circle.png" alt="">
            <div class="requirement_right_already-quoted_content_text" @click="toQuotation2">Quotation Sent</div>
            <img src="../../assets/images/supplier/chevron-right.png" alt="">
          </div>
        </div>

      </div>
    </div>
    <Foot />

    <!-- 拒绝接单弹窗 -->
    <DeclineOrder v-on:customEvent="closeDeclineOrder" :supplierquotation="supplierquotation" v-if="showDeclineOrder" />
  </div>
</template>

<script setup name="RequirementDetails">
import { reactive, ref, toRefs, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Foot from '../../components/Foot.vue'
import { getUserType, getUserId } from '@/utils/auth'
// 拒绝接单弹窗
import DeclineOrder from './dialog/DeclineOrder.vue'
import { getClientArtByRequirementId, getDemandDetail } from '@/api/demand'
import { getDict } from '@/api/common'
import { getMaterialList } from '@/api/user'

// 获取路由参数
const router = useRouter()
const route = useRoute()
const imgList = ref([])
const requirementId = route.query.id
// console.log('主页传递到详情需求id：', requirementId)
// 是否显示下载
const downLoadList = ref([])
const quotationId = ref()
// 我自己发的报价
const myQuotationId = ref()
const currentImgSrc = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodavt.png')
const supplierquotation = ref({})
const items = ref([
  { 'Product Name': 'Box - gift box' },
  { 'Product size': '23mm * 20mm * 40mm' },
  { Material: 'Lvory board' },
  { Color: 'Pantone（17-4911 TPX）' },
  { 'Proess Requirments': 'Concave-convex' },
  { 'Other specific requirments': '-' },
  { 'Overall Budget': '50000.00 EUR' },
  { 'Leadtime Requirment': 'May 1, 2023' },
  { 'Delivery address': 'Seaview Holiday Apartments Ireland' }
  // { 'Sample requirment': 'Yes' }
])

const keys = ref([
  // { name: 'Product1', key: 'productName' },
  { name: 'Quantity', key: 'quantity' },
  { name: 'Product Size (mm)', key: 'specification' },
  { name: 'Material', key: 'material' },
  { name: 'Colour', key: 'pantoneCode' },
  { name: 'Process Requirement', key: 'processRequirement' },
  { name: 'Other Requirement', key: 'otherSpecialRequirent' },
  { name: 'Remark', key: 'remark' }
  // { name: 'Overall Budget', key: 'overallBudget' },
  // { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  // {name:'Estimated Time of Delivery',key:'expectedDeliveryDate'},
  // { name: 'Delivery address', key: 'deliveryAddressId' },
  // { name: 'Sample requirment', key: 'needSample' }
])

// 通用数据
const commonData = reactive([
  { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  // {name:'Estimated Time of Delivery',key:'expectedDeliveryDate'},
  { name: 'Delivery address', key: 'deliveryAddressId' }
])

const initData = () => {
  if (getUserType === '2') {
    commonData.push({ name: 'Overall Budget', key: 'overallBudget' })
  }
}
initData()

// 获取通用数据标题
const getCommonTitle = (key) => {
  return commonData.find(item => item.key === key.key).name
}

const getTitle = (key) => {
  return keys.value.find(item => item.key === key.key).name
}

const getDictData = async (type, key) => {
  const res = await getDict({ type: type, key: key })
  if (res.code === 200) {
    materialValue.value = res.data
  }
}

// 报价状态 0 未报价 1 已拒绝接单 2 已报价
const quotationStatus = ref(0)

// 跳转到报价页面
const toQuotation = () => {
  router.push({
    path: 'quotation',
    query: {
      id: requirementId
    }
  })
}

const toQuotation2 = () => {
  if (myQuotationId.value == null) {
    router.push({
      path: 'quotation',
      query: {
        id: requirementId
      }
    })
  } else {
    router.push({
      path: 'quotation',
      query: {
        id: requirementId,
        qid: myQuotationId.value
      }
    })
  }
}

const demand = ref({})
const materialValue = ref()
const deliveryAddress = ref()
const estimatedTimeOfDelivery = ref()

const getDisplayedValue = (key) => {
  const value = demand.value[key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  case 'material':
    const matchedMaterial = materials.value.find(material => material.id === materialId.value)
    return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  case 'Estimated Time of Delivery':
    return estimatedTimeOfDelivery.value
  default:
    return value
  }
}

const materialId = ref()
// 材质列表
const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  if (res.code === 200) {
    materials.value = res.data
  }
}
getMaterialsList()

// 产品2和后面的产品
let productInfoList = reactive([])

// 产品2和后面的产品的value
const getDisplayedValue2 = (index, key) => {
  const value = productInfoList[index][key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  case 'material':
    const matchedMaterial = materials.value.find(material => material.id === materialId.value)
    return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  default:
    return value
  }
}

// 获取需求单详情
const demandDetail = async () => {
  // console.log('demandDetail in')
  const result = await getDemandDetail(requirementId)
  if (result?.code === 200) {
    console.log('需求单详情', result?.data)
    productInfoList = result?.data?.productInfoList
    console.log('productInfoList', productInfoList)

    demand.value = result?.data
    supplierquotation.value = demand.value
    materialId.value = demand.value.material
    getDictData('eko_material', demand.value.material)
    deliveryAddress.value = demand.value.clientDeliveryAddress?.shippingAddress
    if (demand.value.quotated === false) {
      quotationStatus.value = 0
    } else {
      quotationStatus.value = 2
    }
    if (demand.value.decliend) {
      quotationStatus.value = 1
    }

    for (var item of demand.value?.clientImageList) {
      // console.log(item.imageUrl)
      imgList.value.push(item.imageUrl)
    }
    if (imgList.value.length > 0) {
      currentImgSrc.value = imgList.value[0]
    }
    if (result?.data.supplierQuotationList != null) {
      if (result?.data.supplierQuotationList.length > 0) {
        quotationId.value = result?.data.supplierQuotationList[0].quotationId
        // if(result?.data.supplierQuotationList[0].productionDuration != null){
        //   estimatedTimeOfDelivery.value = result?.data.supplierQuotationList[0].productionDuration
        //   commonData.push({ name: 'Estimated Time of Delivery', key: 'Estimated Time of Delivery' })
        // }
        for (const item of result?.data.supplierQuotationList) {
          if (item.supplier) {
            if (item.supplier.userId) {
              if (item.supplier.userId.toString() === getUserId()) {
                myQuotationId.value = item.quotationId
                if (item.productionDuration) {
                  estimatedTimeOfDelivery.value = item.productionDuration
                  commonData.push({ name: 'Estimated Time of Delivery', key: 'Estimated Time of Delivery' })
                }
              }
            }
          }
        }
      }
    }
  }
}
demandDetail()

// 拒绝订单的理由
const reasonForRefusal = ref('This order has been rejected')

// 弹窗 ----------------
const orderId = reactive({ value: 2356 })
// 向拒绝接单弹窗提供数据
provide('orderId', orderId)

const showDeclineOrder = ref(false)

// 打开拒绝接单弹窗
const toUploadDeclineOrder = () => {
  showDeclineOrder.value = true
  console.log('打开拒绝接单弹窗')
}

// 接收是否关闭拒绝接单弹窗
const closeDeclineOrder = (data) => {
  if (data === 'close') {
    showDeclineOrder.value = false
  } else if (data === 'declineOrder') {
    showDeclineOrder.value = false
    quotationStatus.value = 1
  }
}

const getClientArtByRequirementIdMethod = async () => {
  const result = await getClientArtByRequirementId(requirementId)
  if (result.code === 200) {
    if (result.data.length > 0) {
      // console.log('下载', result.data)
      for (const url of result.data) {
        downLoadList.value.push(url)
        // downloadFile(url)
      }
    }
  }
}

getClientArtByRequirementIdMethod()

// 下载文件
const downLoad = (index) => {
  downloadFile(downLoadList.value[index])
}

const downLoad2 = (url) => {
  downloadFile(url)
}

const downloadFile = (url) => {
  // const a = document.createElement('a')
  // a.href = url // 文件链接
  // a.download = url // 文件名，跨域资源download无效
  // a.click()
  // a.remove()
  window.open(url)
}

</script>

<style lang="scss" scoped>
.container_requirement {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .requirement {
    width: 1600px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    line-height: 1;
    text-align: left;

    &_left {
      // position: fixed;

      &_mainImage {
        width: 360px;
        height: 360px;
        object-fit: cover;
        border-radius: 6px;
        overflow: hidden;
      }
    }

    &_right {
      width: 65%;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      // margin-left: 410px;

      &_titleBox {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 1);
        box-sizing: border-box;
        border-bottom: none;
        background: linear-gradient(180deg, rgba(39, 174, 96, 0.1) 0%, rgba(39, 174, 96, 0) 100%);
        display: flex;
        justify-content: space-between;

        &_title {
          font-size: 24px;
          color: rgba(24, 24, 24, 1);
          font-weight: 700;
          margin: 40px 0 0 50px;
        }

        &_quantity {
          font-size: 20px;
          color: rgba(24, 24, 24, 1);
          font-weight: 600;
          margin: 40px 50px 40px 0;

          span {
            color: rgba(173, 173, 173, 1);
            font-weight: 300;
            margin-right: 10px;
          }
        }
      }

      &_details {
        // margin-top: -10px;

        &_title {
          font-size: 24px;
          color: rgba(24, 24, 24, 1);
          font-weight: 700;
          // border-top: 1px dashed rgba(39, 174, 96, .6);
          padding-top: 20px;
          margin: 20px 50px 0px 50px;
        }

        &_list {
          display: flex;
          margin: 0 50px;
          border-bottom: 1px solid rgba(240, 240, 240, 1);
          padding: 20px 0;

          &_title {
            font-size: 20px;
            color: rgba(150, 150, 150, 1);
            font-weight: 300;
            line-height: 20px;
            width: 180px;
          }

          &_value {
            margin-left: 20px;
            font-size: 20px;
            color: rgba(24, 24, 24, 1);
          }
        }

        .file {
          // border: none !important;
          flex-wrap: wrap !important;

          &_value {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            &_download {
              display: flex;
              align-items: center;
              margin-right: 20px;
              cursor: pointer;

              img {
                margin-left: 2px;
                width: 20px;
                height: 20px;
                object-fit: cover;
              }
            }
          }
        }
      }

      // 通用数据
      .commonData {

        &_list {
          display: flex;
          margin: 0 50px;
          border-bottom: 1px solid rgba(240, 240, 240, 1);
          padding: 20px 0;

          &:first-child {
            border-top: 1px dashed rgba(39, 174, 96, .6) !important;
            margin-top: 50px !important;
          }
        }
      }

      // 按钮
      &_btn {
        display: flex;
        margin-bottom: 60px;
        justify-content: center;
        margin-top: 80px;

        .requirement_btn {
          font-size: 20px;
          font-weight: 700;
          padding: 20px 30px;
          margin: 0 25px;
          border-radius: 4px;
          cursor: pointer;
        }

        &_DeclineOrder {
          color: rgba(255, 151, 0, 1);
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(255, 151, 0, 1);
        }

        &_MakeAQuotation {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(39, 174, 96, 1);
        }
      }

      // 拒绝接单显示
      &_declineOrder {
        &_content {
          margin-top: 80px;
          display: flex;
          margin-bottom: 60px;
          justify-content: center;
          font-size: 20px;
          line-height: 1;
          color: rgba(255, 151, 0, 1);
          font-weight: 700;
          cursor: pointer;

          img {
            margin-right: 5px;
            width: 14px;
            height: 14px;
          }
        }
      }

      // 已报价显示
      &_already-quoted {
        &_content {
          margin-top: 80px;
          display: flex;
          margin-bottom: 60px;
          justify-content: center;
          font-size: 20px;
          line-height: 1;
          color: rgba(39, 174, 96, 1);
          font-weight: 700;
          cursor: pointer;

          &_text {
            margin: 0 5px;
          }

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}
</style>
