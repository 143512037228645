import request from '@/utils/request'

export function addQuotation (data) {
  return request({
    url: '/api/supplierQuotation/addQuotation',
    method: 'post',
    data
  })
}
export function updateQuotation (data) {
  return request({
    url: '/api/supplierQuotation/updateQuotation',
    method: 'post',
    data
  })
}
export function getQuotationDetail (quotationId) {
  return request({
    url: '/api/supplierQuotation/' + quotationId,
    method: 'get'
  })
}

export function getQuotationsByRequirementId (requirementId) {
  return request({
    url: '/api/supplierQuotation/getQuotationsByReId/' + requirementId,
    method: 'get'
  })
}

export function getTransportationInfo (params) {
  return request({
    url: '/api/supplierQuotation/getTransportationInfo',
    method: 'get',
    params
  })
}
export function saveTransportationInfo (data) {
  return request({
    url: '/api/supplierQuotation/saveTransportationInfo',
    method: 'post',
    data
  })
}

export function declineQuotation (data) {
  return request({
    url: '/api/supplierQuotation/declienQuotation',
    method: 'post',
    data
  })
}
